import * as React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Article from "../components/Article";

const NotFound = () => {
  const { t } = useTranslation("404");
  const title = t("notFound");

  return (
    <Layout width="narrow">
      <Seo title={title} />
      <Article title={title} />
    </Layout>
  );
};

export default NotFound;
